@font-face {
  font-family: "mainFont";
  src: url("../src/content/Barlow-Light.ttf") format("truetype");
}
@font-face {
  font-family: "heading";
  src: url("../src/content/RussoOne-Regular.ttf") format("truetype");
  font-weight: 700;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

/* Masonry layout portfolio */

.masonry-layout {
  column-gap: 1em;
  width: 100%;
  column-count: 1;
}

.masonry-item {
  break-inside: avoid;
}

/* For tablets: */
@media screen and (min-width: 481px) {
  .masonry-layout {
    column-count: 2;
  }
}

/* For desktop: */
@media screen and (min-width: 769px) {
  .masonry-layout {
    column-count: 3;
  }
}

.gradient-border {
  border-top: 1px solid;
  border-image-source: linear-gradient(
    to left,
    rgba(255, 0, 0, 0),
    #32a0f5,
    rgba(255, 0, 0, 0)
  );
  border-image-slice: 1;
}

.gradient-section {
  background: linear-gradient(to top, #363431, #57585b);
}

/* Header fade */

.animated-header {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
